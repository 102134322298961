<template>
    <div>
        <input class="form-control my-3" v-model="filter" :placeholder="$t('autoscreening.filter.placeholder')">
        <div class="row my-3">
            <div class="col-3">
                <select name="page_autoscreening" v-model="perPage" class="form-select" >
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>100</option>
                    <option>1000</option>
                </select>
            </div>
            <div class="col-7 text-center">
                <button class="btn btn-primary mx-2" @click="setPage(minPage)" v-if="minPage != page"><<</button>
                <button class="btn btn-primary mx-2" @click="setPage(page - 1)"v-if="previousPage != page"><</button>
                <button class="btn btn-primary mx-2" @click="setPage(page + 1)"v-if="nextPage != page">></button>
                <button class="btn btn-primary mx-2" @click="setPage(maxPage)" v-if="maxPage != page">>></button>
            </div>
            <div class="col-2">
                <span>{{ $t("autoscreening.page", {page: page, maxPage: maxPage}) }}</span>
            </div>
        </div>
        <table class="w-100 admin_table">
            <tr class="row px-3 py-2 table_header sticky-top">
                <th class="col-4">{{ $t("autoscreening.subject") }}</th>
                <th class="col-2">{{ $t("autoscreening.subject") }}</th>
                <th class="col-2">{{ $t("autoscreening.subject") }}</th>
                <th class="col-2">{{ $t("autoscreening.lastChange") }}</th>
            </tr>
            <tr class="row px-3 py-2 table_row" v-for="autoscreening in autoscreenings">
                <td class="col-4">{{ autoscreening.body_name }}</td>
                <td class="col-2">{{ autoscreening.body_organization_id }}</td>
                <td class="col-2">
                    <span v-if="autoscreening.actual_state">Má riziko</span>
                    <span v-else>Nemá riziko</span>
                </td>
                <td class="col-2">{{ autoscreening.last_change }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import axios from "axios";

const api = axios.create({
    baseURL: "/admin/app/api/autoscreenings",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            autoscreenings: [],
            institution: null,
            filter: null,
            page: 1,
            count: 0,
            perPage: 100,
            isLoading: false,
            cancelToken: null,
            searchTimer: null,
            debounceTime: 700
        }
    },
    mounted: function () {
        this.$i18n.locale = document.getElementById("language").innerText;
        this.institution = document.getElementById("institution").innerText;
        this.searchAutoscreenings();
    },
    computed: {
        maxPage: function () {
            return Math.ceil(this.count/this.perPage)
        },
        minPage: function () {
            return 1
        },
        previousPage: function () {
            if (this.page == this.minPage) {
                return this.minPage;
            }
            return this.page - 1;
        },
        nextPage: function () {
            if (this.page == this.maxPage) {
                return this.maxPage;
            }
            return this.page + 1;
        }
    },
    watch: {
        filter: function (selectedSubject) {
            this.searchAutoscreenings();
        },
        page: function (selectedSubject) {
            this.searchAutoscreenings();
        },
        perPage: function (selectedSubject) {
            this.searchAutoscreenings();
        }
    },
    methods: {
        setPage: function (page) {
            this.page = page;
        },
        searchAutoscreenings: function () {
            var query = this.filter;
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }

            if (this.cancelToken != null) {
                this.cancelToken.cancel("Operation canceled by the user.");
                this.cancelToken = null;
            }

            this.isLoading = true;
            this.subjects = [];
            this.actualString = query;

            let params = {
                q: query,
                per_page: this.perPage,
                page: this.page
            }

            this.searchTimer = setTimeout(function () {
                this.cancelToken = axios.CancelToken.source();
                api.get("/" + this.institution, { params: params, cancelToken: this.cancelToken.token })
                    .then(r => {
                        if (query == this.actualString) {
                            this.autoscreenings = r.data.items;
                            this.count = r.data.count;
                        }
                    })
                    .catch(e => {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log(e);
                        }
                    })
                    .finally(() => {
                        this.cancelToken = null;
                        if (query == this.actualString) {
                            this.isLoading = false;
                        }
                    })
            }.bind(this), this.debounceTime);
        },
    }
}
</script>

<style scoped>

</style>
