import { createApp } from 'vue'
import './scss/index.scss'
import App from './App.vue'
import Autoscreening from './Autoscreening.vue'
import InternationalLoading from './InternationalLoading.vue'
import { Modal } from "bootstrap";
import i18n from "./messages";

createApp(App).use(i18n).mount('#org_selector')

createApp(Autoscreening).use(i18n).mount('#autoscreening_table')
createApp(InternationalLoading).use(i18n).mount('#international_loading')

// raw JS for selected product on add_credit.html
document.querySelectorAll("input[name='product_type']").forEach((box) => {
    box.addEventListener("click", function (event) {
        changePriceSelected(event.target.value)
    });
    if (box.checked) {
        changePriceSelected(box.value)
    }
});

function changePriceSelected(value) {
    var el = document.getElementById(value);
    var selected = document.getElementsByClassName("selectedPriceItem")
    for (var i = 0; i < selected.length; i++) {
        selected[i].classList.remove("selectedPriceItem");
    }
    el.classList.add("selectedPriceItem");
}
